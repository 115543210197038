import { Button, Grid } from '@mui/material';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import _ from 'underscore';
import DraggableList, { Item } from '../../../shared/components/DraggableList';
import ErrorMessage from '../../../shared/components/ErrorMessage';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { Textarea } from '../../../shared/components/TextArea';
import { EvaluationReview, ReviewStep } from '../../../shared/domain/review';
import { screenMdMin } from '../../../shared/styles';
import { EVALUATION_FORM_FIELDS } from '../data';
import {
  Container,
  StyledText,
  StyledTextField,
} from '../styles/review.styles';
import ReviewFormButtons from './ReviewFormButtons';
import MultipleChoiceReviewQuestion, {
  StyledQuestionText,
} from './MultipleChoiceReviewQuestion';

const ComponentWrapper = styled.div`
  @media ${screenMdMin} {
    display: flex;
  }
`;

const StyledButton = styled(Button)`
  margin-left: var(--spacing-md);
  margin-top: var(--spacing-xs);
  margin-bottom: var(--spacing-md);
  @media ${screenMdMin} {
    margin-top: var(--spacing-sm);
  }
`;

let nextId = 1;
const nextId2 = 1;

type EvaluationReviewProps = {
  submitReviewForm: (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    reviewStep: ReviewStep,
    isDraft?: boolean
  ) => void;
  loading?: boolean;
  setLoading: (loading: boolean) => void;
  currentReview: EvaluationReview;
  isViewMode?: boolean;
};

const EvaluationReviewForm = (props: EvaluationReviewProps) => {
  const { submitReviewForm, loading, setLoading, currentReview, isViewMode } =
    props;
  const [formFields, setFormFields] = useState<EvaluationReview>(
    {} as EvaluationReview
  );
  const [currentApprover, setCurrentApprovers] = useState<string>('');
  const [approvers, setApprovers] = useState<Item[]>([]);
  const [platforms, setPlatforms] = useState<Item[]>([]);
  const [currentPlatform, setCurrentPlatform] = useState<string>('');

  const handleFormFieldChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormFields({
      ...formFields,
      [name]: value,
    });
  };

  const handleApproversChange = (approverName: string) => {
    setApprovers([...approvers, { id: String(nextId++), text: approverName }]);
    setCurrentApprovers('');
  };

  const handlePlatformsChange = (platformName: string) => {
    setPlatforms([...platforms, { id: String(nextId2), text: platformName }]);
    setCurrentPlatform('');
  };

  useEffect(() => {
    setFormFields({
      ...formFields,
      approversList: approvers.map((approversList: Item) => {
        return approversList.text;
      }),
    });
  }, [approvers]);

  useEffect(() => {
    setFormFields({
      ...formFields,
      platFormList: platforms?.map((platFormList: Item) => {
        return platFormList.text;
      }),
    });
  }, [platforms]);

  const [errors, setErrors] = useState<any>({});

  const validate = () => {
    const errors: any = {};

    let requiredFields = [
      'buyerReplacingVendor',
      'requireTrial',
      'criteriaArticulatedByBuyerComment',
      'trialAccommodated',
      'criteriaArticulatedByBuyer',
      'noDecisionMakers',
      'criteriaChangeComment',
      'securityRequirementsComment',
      'durationSecurityReview',
      'securityDifficulty',
      'submitRoi',
    ];

    if (formFields.criteriaArticulatedByBuyer === 'Yes') {
      requiredFields = requiredFields.filter(
        (field) => field !== 'criteriaArticulatedByBuyerComment'
      );
    }

    requiredFields.forEach((field) => {
      //@ts-ignore
      if (!formFields[field]) {
        errors[field] = 'This field is required';
      }
    });

    setErrors(errors);
    return !_.isEmpty(errors);
  };

  const submitEvaluationReview = (e: any) => {
    e.preventDefault();
    const hasErrors = validate();

    if (hasErrors) {
      return;
    }
    submitReviewForm(e, formFields, ReviewStep.EVALUATION, true);
  };

  useEffect(() => {
    setLoading(true);
    setFormFields(currentReview ?? ({} as EvaluationReview));
    setApprovers(
      currentReview?.approversList?.map((approver) => {
        return { id: String(nextId++), text: approver };
      }) ?? []
    );
    setPlatforms(
      currentReview?.platFormList?.map((platform) => {
        return { id: String(nextId++), text: platform };
      }) ?? []
    );
    setLoading(false);
  }, [currentReview]);

  if (loading) {
    return <LoadingProgress />;
  }

  return (
    <Container>
      <form>
        <Grid container>
          {EVALUATION_FORM_FIELDS.map((question) => (
            <Grid item sm={6} xs={12} key={question.name}>
              <MultipleChoiceReviewQuestion
                errors={errors}
                isViewMode={isViewMode}
                question={question}
                formFields={formFields}
                handleFormFieldChange={handleFormFieldChange}
                isNarrow={question.options.length > 3}
                isWide
              />
            </Grid>
          ))}
        </Grid>
        <Grid container>
          {formFields.criteriaArticulatedByBuyer === 'No' && (
            <Grid item sm={6} xs={12}>
              <StyledText>
                Please share why the evaluation criteria was not clearly
                articulated
              </StyledText>
              <Textarea
                readOnly={isViewMode}
                onChange={handleFormFieldChange}
                name="criteriaArticulatedByBuyerComment"
                value={formFields.criteriaArticulatedByBuyerComment}
                minRows={4}
                style={{ width: '95%', resize: 'none' }}
                required
              />
              {errors.criteriaArticulatedByBuyerComment && (
                <ErrorMessage
                  error={errors.criteriaArticulatedByBuyerComment}
                />
              )}
            </Grid>
          )}
          <Grid item sm={6} xs={12}>
            <StyledText>
              Did the buyer change the evaluation criteria, and if so, how?
            </StyledText>
            <Textarea
              readOnly={isViewMode}
              style={{ width: '95%', resize: 'none' }}
              onChange={handleFormFieldChange}
              name="criteriaChangeComment"
              value={formFields.criteriaChangeComment}
              minRows={4}
              required
            />
            {errors.criteriaChangeComment && (
              <ErrorMessage error={errors.criteriaChangeComment} />
            )}
          </Grid>
          <Grid item sm={6} xs={12}>
            <StyledText>
              Are there any specific requirements you must meet to pass the
              security review?
            </StyledText>
            <Textarea
              onChange={handleFormFieldChange}
              readOnly={isViewMode}
              name="securityRequirementsComment"
              value={formFields.securityRequirementsComment}
              minRows={4}
              style={{ width: '95%', resize: 'none' }}
              required
            />
            {errors.securityRequirementsComment && (
              <ErrorMessage error={errors.securityRequirementsComment} />
            )}
          </Grid>
        </Grid>
        &nbsp;
        <ComponentWrapper>
          <Grid style={{ width: '100%' }} item sm={6} xs={12}>
            <StyledQuestionText
              isWide
              style={{ whiteSpace: 'nowrap', maxWidth: '75%' }}
            >
              Please add all approvers titles (no names) in chronological order:
              &nbsp;
            </StyledQuestionText>
            <StyledTextField
              isWide
              value={currentApprover}
              name="approversList"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentApprovers(e.target.value)
              }
              placeholder="Once added, a list will populate below"
              size="small"
              InputProps={{
                readOnly: isViewMode,
              }}
            />

            <StyledButton
              variant="outlined"
              disabled={!currentApprover}
              onClick={() => handleApproversChange(currentApprover)}
            >
              Add Approver
            </StyledButton>

            <DraggableList items={approvers} />
          </Grid>
          <Grid style={{ width: '100%' }} item sm={6} xs={12}>
            <StyledQuestionText
              style={{ whiteSpace: 'nowrap', maxWidth: '75%' }}
            >
              Please add all platforms you were required to integrate with as
              part of the process:
            </StyledQuestionText>
            <StyledTextField
              isWide
              value={currentPlatform}
              name="platFormList"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
                setCurrentPlatform(e.target.value)
              }
              required
              placeholder="Once added, a list will populate below"
              size="small"
              InputProps={{
                readOnly: isViewMode,
              }}
            />

            <StyledButton
              variant="outlined"
              disabled={!currentPlatform}
              onClick={() => handlePlatformsChange(currentPlatform)}
            >
              Add Platform
            </StyledButton>
            <DraggableList items={platforms} />
          </Grid>
        </ComponentWrapper>
        <ReviewFormButtons
          handleContinueReview={(e: React.FormEvent<HTMLFormElement>) =>
            submitEvaluationReview(e)
          }
          submitButtonText="Save Review as Draft"
          nextButtonText="Move onto Legal"
          onSubmit={(e) =>
            submitReviewForm(e, formFields, ReviewStep.EVALUATION)
          }
        />
      </form>
    </Container>
  );
};

export default EvaluationReviewForm;
