import { Slide } from '@mui/material';
import Dialog from '@mui/material/Dialog';
import { TransitionProps } from '@mui/material/transitions';
import axios from 'axios';
import * as React from 'react';
import { useContext, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { UserContext } from '../../../shared/contexts/UserContext';
import { BasicReview, ReviewStep } from '../../../shared/domain/review';
import BasicReviewForm from './BasicReviewForm';
import EvaluationReviewForm from './EvaluationReviewForm';
import LegalReviewForm from './LegalReviewForm';
import NegotiationReviewForm from './NegotiationReviewForm';
import PurchaseDetailsReviewForm from './PurchaseDetailsReviewForm';
import ReviewDialogHeader from './ReviewDialogHeader';
import ReviewStepper from './ReviewStepper';
import RfpReviewForm from './RfpReviewForm';

type ReviewDialogProps = {
  open: boolean;
  companyName?: string;
  companyId?: number;
  setOpen: (isOpen: boolean) => void;
  cancelRating: (isOpen?: boolean) => void;
  ratingSuccess: () => void;
  isViewMode?: boolean;
  reviewViewId?: number;
  setIsViewMode?: (isViewMode: boolean) => void;
  setReviewViewId?: (reviewViewId: number | null) => void;
  getAllReviews?: () => void;
  isAddCompany?: boolean;
};

export const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ReviewDialog = (props: ReviewDialogProps) => {
  const {
    open,
    setOpen,
    companyName,
    cancelRating,
    ratingSuccess,
    companyId,
    reviewViewId,
    isViewMode,
    setIsViewMode,
    setReviewViewId,
    getAllReviews,
    isAddCompany,
  } = props;
  const [currentReviewId, setCurrentReviewId] = useState<number | null>(null);
  const [loading, setLoading] = useState(false);
  const [currentReview, setCurrentReview] = useState<BasicReview | any>(
    {} as BasicReview
  );
  const [rfp, setRfp] = useState(false);
  const [reviewStep, setReviewStep] = useState<ReviewStep>(
    ReviewStep.BASIC_REVIEW
  );

  const { setReload, reload, user } = useContext(UserContext);

  const { reviewId } = useParams();
  const navigate = useNavigate();

  const handleClose = () => {
    if (reviewId) {
      navigate(`/company-search/${companyName}`, { replace: true });
    }
    setOpen(false);
    setReload(reload + 1);
    setIsViewMode && setIsViewMode(false);
    setReviewViewId && setReviewViewId(null);
    setCurrentReview({});
  };

  const handleBack = (step: ReviewStep) => {
    setReviewStep(step);
  };

  const successDeal = (isViewMode?: boolean) => {
    if (!isViewMode) {
      ratingSuccess();
    }
    setCurrentReviewId(null);
    setReviewViewId && setReviewViewId(null);
    setIsViewMode && setIsViewMode(false);
    setCurrentReview({});
    setReload(reload + 1);
    setReviewStep(ReviewStep.BASIC_REVIEW);
    setOpen(false);
  };

  const setReviewToDeepDive = async () => {
    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review/${currentReviewId}/promote`,
        {},
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      getAllReviews && getAllReviews();
    } catch (error) {
      cancelRating();
    }
  };

  const submitReviewForm = async (
    e: React.FormEvent<HTMLFormElement>,
    formFields: any,
    key: ReviewStep,
    continueReview?: boolean,
    isViewMode?: boolean
  ) => {
    if (isViewMode) return;
    e.preventDefault();

    const reviewInput = {
      ...formFields,
      key: key,
      ReviewId: currentReviewId,
    };

    try {
      await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/review`,
        reviewInput,
        {
          headers: {
            Authorization: user?.idToken,
          },
        }
      );

      setReload(reload + 1);
    } catch (error) {
      cancelRating();
    }

    if (continueReview) {
      if (key === ReviewStep.RFP || (key === ReviewStep.NEGOTIATION && !rfp)) {
        await setReviewToDeepDive();
        setReload(reload + 1);
        successDeal(isViewMode || false);
        return;
      }
      setReviewStep(reviewStep + 1);
      return;
    }
  };

  const getReview = async (reviewId: number, key: ReviewStep) => {
    if (!reviewId) return;
    try {
      const params = key !== undefined ? { key } : {};

      const res = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/review/${reviewId}`,
        {
          params,
          headers: {
            Authorization: user?.idToken,
          },
        }
      );
      setCurrentReview(res.data);
    } catch (error) {}
  };

  useEffect(() => {
    if (!currentReviewId) return;
    getReview(currentReviewId, reviewStep);
  }, [reviewStep]);

  useEffect(() => {
    if (!currentReviewId && !reviewViewId) return;
    reviewViewId && getReview(reviewViewId, reviewStep);
  }, [open]);

  if (loading) return <LoadingProgress />;

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      fullScreen
      TransitionComponent={Transition}
    >
      <ReviewDialogHeader
        handleClose={handleClose}
        companyName={companyName || 'a New Company'}
        isDeepDive={!!reviewStep}
        isViewMode={isViewMode || false}
      />
      {reviewStep !== ReviewStep.BASIC_REVIEW && (
        <ReviewStepper step={reviewStep} handleBack={handleBack} rfp={rfp} />
      )}
      {reviewStep === ReviewStep.BASIC_REVIEW && (
        <BasicReviewForm
          successDeal={successDeal}
          companyId={companyId || 0}
          companyName={companyName || 'New Company'}
          cancelRating={cancelRating}
          reload={reload}
          setReload={setReload}
          setReviewStep={setReviewStep}
          setCurrentReviewId={setCurrentReviewId}
          currentReviewId={currentReviewId}
          currentReview={currentReview}
          setRfp={setRfp}
          rfp={rfp}
          isViewMode={isViewMode || false}
          isAddCompany={isAddCompany}
        />
      )}
      {reviewStep === ReviewStep.PURCHASE_DETAILS && (
        <PurchaseDetailsReviewForm
          submitReviewForm={submitReviewForm}
          currentReview={currentReview}
          setLoading={setLoading}
          isViewMode={isViewMode || false}
        />
      )}
      {reviewStep === ReviewStep.EVALUATION && (
        <EvaluationReviewForm
          submitReviewForm={submitReviewForm}
          currentReview={currentReview}
          setLoading={setLoading}
          isViewMode={isViewMode || false}
        />
      )}
      {reviewStep === ReviewStep.LEGAL && (
        <LegalReviewForm
          submitReviewForm={submitReviewForm}
          currentReview={currentReview}
          setLoading={setLoading}
          isViewMode={isViewMode || false}
        />
      )}
      {reviewStep === ReviewStep.NEGOTIATION && (
        <NegotiationReviewForm
          submitReviewForm={submitReviewForm}
          currentReview={currentReview}
          setLoading={setLoading}
          rfp={rfp}
          isViewMode={isViewMode || false}
          setOpen={setOpen}
        />
      )}
      {reviewStep === ReviewStep.RFP && (
        <RfpReviewForm
          submitReviewForm={submitReviewForm}
          currentReview={currentReview}
          setLoading={setLoading}
          isViewMode={isViewMode || false}
        />
      )}
    </Dialog>
  );
};

export default ReviewDialog;
