import {
  MultipleChoiceQuestion,
  Question,
  QuestionType,
  TextQuestion,
} from '../../../shared/domain/form';

export const BASIC_REVIEW_FORM_FIELDS: Question[] = [
  {
    name: 'dealArea',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Which part of the organization did you sell to?',
    options: [
      'Customer Success',
      'Engineering',
      'Finance',
      'HR',
      'Legal',
      'Marketing',
      'Operations',
      'Product',
      'Sales',
      'IT',
      'Security',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'dealSize',
    question: 'What was the annual contract value of the deal?',
    type: QuestionType.MULTIPLE_CHOICE,
    options: [
      '0 - 25k',
      `25k - 50k`,
      `50k - 100k`,
      `100k - 250k`,
      `250k - 500k`,
      `500k - 1M`,
      '1MM+',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'dealType',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What type of deal is this?',
    options: ['New Business', 'Renewal', 'Expansion/Upsell'],
  } as MultipleChoiceQuestion,
  {
    name: 'dealLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Term of the deal?',
    options: ['Less than 1 year', '1 Year', '2 Years', '3 Years+'],
  } as MultipleChoiceQuestion,
  {
    name: 'dealClose',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you win the deal?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'dealRfp',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did this deal include an RFP?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'commentBuyerWell',
    type: QuestionType.TEXT,
    question: 'What did the buyer do well?',
  } as TextQuestion,
  {
    name: 'commentBuyerBetter',
    question: 'What could the buyer have done better?',
    type: QuestionType.TEXT,
  } as TextQuestion,
  {
    name: 'commentWinLose',
    question: 'Why did you win/lose the deal?',
    type: QuestionType.TEXT,
  } as TextQuestion,
  {
    name: 'commentAdvice',
    question: 'What advice do you have for the next seller?',
    type: QuestionType.TEXT,
  } as TextQuestion,
];

export const PURCHASE_DETAILS_FORM_FIELDS = [
  {
    name: 'championEffectiveness',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'On a scale of 1 - 10, how effective was your champion at getting the deal done?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10'],
  } as MultipleChoiceQuestion,
  {
    name: 'definedBudget',
    question: 'Was there a defined budget for this purchase?',
    options: ['Yes', 'No', 'Unknown'],
  } as MultipleChoiceQuestion,
  {
    name: 'salesCycleLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'How long was the sales cycle from start to finish?',
    options: [
      '< 1 Month',
      '1 - 3 Months',
      '3 - 6 Months',
      '6 - 12 Months',
      '12+ Months',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'buyerFiscalYearEnd',
    type: QuestionType.MULTIPLE_CHOICE,
    question: "When does the buyer's fiscal year end?",
    options: [
      'Jan',
      'Feb',
      'Mar',
      'Apr',
      'May',
      'Jun',
      'Jul',
      'Aug',
      'Sep',
      'Oct',
      'Nov',
      'Dec',
      'Unknown',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'paymentCycle',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did the customer agree to pay monthly, quarterly, annually or other?',
    options: ['Monthly', 'Quarterly', 'Annually', 'Other', 'N/A'],
  } as MultipleChoiceQuestion,
];

export const EVALUATION_FORM_FIELDS = [
  {
    name: 'buyerReplacingVendor',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was the buyer replacing an incumbent vendor?',
    options: ['Yes', 'No', 'Unknown'],
  } as MultipleChoiceQuestion,
  {
    name: 'submitRoi',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Were you required to submit an ROI or other business justification to win this deal?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'requireTrial',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did the customer request a trial??',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'noDecisionMakers',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'How many decision makers were involved in the evaluation process?',
    options: ['1', '2-3', '4-5', '5+'],
  } as MultipleChoiceQuestion,
  {
    name: 'trialAccommodated',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was a trial accommodated?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'durationSecurityReview',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What was the duration of the security review?',
    options: [
      'Not Required',
      '< Week',
      '2 Weeks',
      '3 Weeks',
      '4+ Weeks',
      'N/A',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'criteriaArticulatedByBuyer',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was the evaluation criteria clearly articulated by the buyer?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'securityDifficulty',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'On a scale of 1 - 10, how difficult was it to pass the security review?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'N/A'],
  } as MultipleChoiceQuestion,
];

export const LEGAL_FORM_FIELDS = [
  {
    name: 'legalReviewLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What was the duration of the legal review?',
    options: [
      'Not Required',
      '1-2 Weeks',
      '3-4 Weeks',
      '1-3 Months',
      '3-6 Months',
      '>6 Months',
      'N/A',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'legalReviewDifficulty',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'On a scale of 1 - 10, how difficult was it to pass the legal review?',
    options: ['1', '2', '3', '4', '5', '6', '7', '8', '9', '10', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'marketingPermission',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did they give you permission to use their logo and/or name in marketing materials?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'autoRenewalClause',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was there an auto-renewal clause in the contract?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'paperworkOption',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did the customer agree to your companies contracting paperwork or did they require use of their own?',
    options: [
      'Our contracting paperwork',
      'Their contracting paperwork',
      'N/A',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'customerAskLiability',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'What did the customer ask for in Limitation of Liability/Indemnification?',
    options: [
      '1-3x ARR',
      '4-5x ARR',
      '6-10x ARR',
      '10-15x ARR',
      '16-20x ARR',
      '20x+ ARR',
      'Unlimited',
      'N/A',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'endAskLiability',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'What did you end up with with Limitation of Liability/Indemnification?',
    options: [
      '1-3x ARR',
      '4-5x ARR',
      '6-10x ARR',
      '10-15x ARR',
      '16-20x ARR',
      '20x+ ARR',
      'Unlimited',
      'N/A',
    ],
  } as MultipleChoiceQuestion,
];

export const NEGOTIATION_FORM_FIELDS = [
  {
    name: 'terminationConvenience',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did buyer request a termination for convenience clause?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'forcedTerminationAcceptance',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you accept this request?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'forcedTerminationAcceptanceComment',
    type: QuestionType.TEXT,
    question:
      'How did you negotiate the termination for convenience clause out of the contract?',
  } as TextQuestion,
  {
    name: 'noticeSelection',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you receive notice that you were the selected vendor?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'noticeHonored',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was this notice honored?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'procurementThreshold',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did the buyer state a threshold at which procurement would be required to get involved?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'procurementFairness',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did procurement treat you fair and honest?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'procurementTactics',
    type: QuestionType.MULTIPLE_CHOICE,
    question:
      'Did procurement/buyer use any tactics to force you to take unfavorable terms and/or additional discounts?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'competitiveBids',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did procurement require competitive bids?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'fundsReleased',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Was a business case required to get funds released?',
    options: ['Yes', 'No', 'N/A'],
  } as MultipleChoiceQuestion,
  {
    name: 'discountGiven',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'What discount did you ultimately give to win the deal?',
    options: [
      '0%',
      '1% - 10%',
      '11% - 20%',
      '21 - 30%',
      '31 - 40%',
      '41 - 50%',
      '51%+',
    ],
  } as MultipleChoiceQuestion,
  {
    name: 'negotiationTipsComment',
    question: 'Any tips to other sellers to help negotiate with this customer?',
    type: QuestionType.TEXT,
  } as TextQuestion,
  {
    name: 'fairnessComment',
    question:
      'Please add details regarding the procurement process, such as the honesty of the buyer, any tactics used, etc.',
    type: QuestionType.TEXT,
  } as TextQuestion,
];

export const RFP_FORM_FIELDS = [
  {
    name: 'rfpWin',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you win the RFP?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'rfpInvolvement',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Were you involved in the RFP process?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'rfpInfluence',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'Did you have influence over the RFP?',
    options: ['Yes', 'No'],
  } as MultipleChoiceQuestion,
  {
    name: 'rfpLength',
    type: QuestionType.MULTIPLE_CHOICE,
    question: 'How long was the RFP from initiation to final decision?',
    options: ['< 1 Month', '1 - 3 Months', '3 - 6 Months', '6+ Months'],
  } as MultipleChoiceQuestion,
];
