import styled from 'styled-components';
import {
  Breakpoints,
  H1,
  H2,
  H3,
  P,
  screenMdMin,
  screenUltraMin,
} from '../../shared/styles';
import { Chip } from '@mui/material';
import { useState, useCallback, useEffect } from 'react';
import useWindowResize, {
  WindowSizeEnum,
  Dimensions,
} from '../../shared/hooks/useWindowResize';
import PreLoginBanner from './components/PreLoginBanner';

type PreLoginProps = {
  setIsNavOpen: (isOpen: boolean) => void;
};

const StyledChip = styled(Chip)<{ type?: string }>`
  color: ${({ type }) =>
    type === 'light' ? '#00d2ff' : type === 'dark' ? '#00d2ff' : '#e6f3f8'};
  background-color: ${({ type }) =>
    type === 'light' ? '#e6f3f8' : type === 'dark' ? '#000053' : '#00d2ff'};
  max-width: fit-content;
  display: inline-flex;
`;

const Panel = styled.div<{ color?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 650px;
  background-color: ${({ color }) =>
    color === 'white'
      ? 'var(--color-white)'
      : color === 'dark'
        ? '#0f2867'
        : '#F5FBFF'};
  padding: var(--spacing-md);
  flex: 0 0 30%;
  box-sizing: border-box;
`;

const Image = styled.img`
  margin-left: 8%;
  width: 40%;
  @media ${screenMdMin} {
    width: 30%;
  }
`;

const BottomPanel = styled.div`
  display: flex;
  flex-direction: column;
  @media ${screenMdMin} {
    flex-direction: row;
  }
`;

const CenteredTextContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const TextContainer = styled.div<{ marginscope?: string; color?: string }>`
  display: flex;
  flex-direction: column;
  margin-left: ${({ marginscope }) => (marginscope === 'auto' ? 'auto' : '8%')};
  margin-right: var(--spacing-lg);
  justify-content: center;
  @media ${screenMdMin} {
    width: 45%;
  }
`;

const StyledCompanyLogoImage = styled.img`
  height: 80%;
  width: 85%;
  border-radius: 12px;
  margin: auto;
  display: block;
`;

const StyledFlexContainer = styled.div`
  display: flex;
  height: 100%;
  margin-right: 48px;
  max-width: 50%;
`;

const CompanyLogoImageContainer = styled.div`
  background-color: #ebf0fb;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 20%;
  height: 74px;
  width: 80px;
  margin: var(--spacing-md);
  @media ${screenMdMin} {
    height: 110px;
    width: 125px;
  }
`;

const StyledH1 = styled(H1)<{ color?: string }>`
  margin-bottom: 10px;
  color: ${({ color }) =>
    color === 'white' ? 'var(--color-white)' : 'var(--color-black)'};
`;

const TextBoxContainer = styled.div<{ isWide?: boolean }>`
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  max-width: ${({ isWide }) => (isWide ? '90%' : '65%')};
  margin-bottom: var(--spacing-sm);
`;

const TextBox = styled.div<{ isWide?: boolean }>`
  flex-direction: column;
  padding: var(--spacing-md);
  background-color: #e6f3f8;
  border-radius: 12px;
  margin-bottom: var(--spacing-sm);
  min-width: 100%;
  @media ${screenMdMin} {
    min-width: 0;
    width: ${({ isWide }) => (isWide ? '32%' : '24%')};
    &:nth-child(n + 5) {
      width: 400px;
    }
  }
  @media ${screenUltraMin} {
    &:nth-child(n + 5) {
      width: 550px;
    }
  }
`;

const TextBoxHeader = styled(H2)`
  color: #00d2ff;
`;

const StyledP = styled(P)<{ color?: string }>`
  line-height: 1.7;
  font-size: 18px;
  margin-bottom: 15px;
  color: ${({ color }) =>
    color === 'white' ? 'var(--color-white)' : 'var(--color-black)'};
`;

const BannerHeader = styled(H3)<{ spacetop?: boolean }>`
  text-align: center;
  color: var(--color-white);
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translate(-50%, -50%);
  margin-top: ${({ spacetop }) => (spacetop ? '40px' : '0')};
  font-size: 18px;
  @media ${screenMdMin} {
    position: absolute;
    font-size: 32px;
    left: 50%;
    top: 30%;
    transform: translate(-50%, -50%);
    margin-top: ${({ spacetop }) => (spacetop ? '85px' : '0px')};
  }
`;

const BannerText = styled(P)`
  font-size: 12px;
  text-align: center;
  display: flex;
  flex-direction: column;
  color: var(--color-white);
  position: absolute;
  top: 18%;
  left: 50%;
  transform: translate(-50%, -50%);
  @media ${screenMdMin} {
    color: var(--color-white);
    position: absolute;
    font-size: 18px;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`;

const StyledImage = styled.img`
  width: 100%;
  height: auto;
`;

const FEATURES_AND_BENEFITS_MAPPINGS = [
  {
    subtitle: 'Company Profiles',
    imageLink: require('../../assets/images/welcomePage/Company Profiles.png'),
    text: 'Know how your buyer operates before you ever connect with them.',
    alt: 'Company Profiles icon',
  },
  {
    subtitle: 'Deep Dive Reviews',
    imageLink: require('../../assets/images/welcomePage/Deep Dive Reviews.png'),
    text: 'Understand the nitty gritty of the purchasing process. Learn how to negotiate with your key prospects!',
    alt: 'Deep Dive Reviews icon',
  },
  {
    subtitle: 'Enhanced Seller Data',
    imageLink: require('../../assets/images/welcomePage/Enhanced Seller Data.png'),
    text: 'Wanna know what % of deals close with a certain buyer? If they do multi-year deals? We got you covered.',
    alt: 'Enhanced Seller Data icon',
  },
  {
    subtitle: 'Leave Reviews',
    imageLink: require('../../assets/images/welcomePage/Leave Reviews.png'),
    text: 'Join the community! Leave reviews and gather Kandir points, unlocking deep process information about your most important accounts.',
    alt: 'Leave Reviews icon',
  },
  {
    subtitle: 'Home Feed',
    imageLink: require('../../assets/images/welcomePage/Home feed.png'),
    text: 'See all the reviews you care about in one place.',
    alt: 'Home Feed icon',
  },
  {
    subtitle: 'Company Search',
    imageLink: require('../../assets/images/welcomePage/Company search.png'),
    text: 'Quickly find high priority targets in your selling portfolio.',
    alt: 'Company Search icon',
  },
  {
    subtitle: 'Follow Companies',
    imageLink: require('../../assets/images/welcomePage/Follow Companies.png'),
    text: "Don't miss a beat! Get notified when new ratings are in for prospects you care about!",
    alt: 'Follow Companies icon',
  },
];

const PreLogin = (props: PreLoginProps) => {
  const { setIsNavOpen } = props;
  const [screenSize, setScreenSize] = useState<WindowSizeEnum>(
    window.innerWidth > Breakpoints.md
      ? WindowSizeEnum.LARGE
      : WindowSizeEnum.SMALL
  );

  const setSize = useCallback((dimensions: Dimensions) => {
    if (dimensions.width > Breakpoints.md) {
      setScreenSize(WindowSizeEnum.LARGE);
    } else {
      setScreenSize(WindowSizeEnum.SMALL);
    }
  }, []);

  useWindowResize(setSize);

  useEffect(() => {
    setIsNavOpen(false);
  }, []);

  return (
    <>
      <PreLoginBanner />
      <Panel color="white">
        {screenSize === WindowSizeEnum.LARGE && (
          <Image
            src={require('../../assets/images/welcomePage/womanStockWelcomePage.png')}
            alt="Welcome Image"
          />
        )}
        <TextContainer>
          <StyledChip type="light" label="About Us" />
          <StyledH1>The Quickest Path to Increased Sales Success</StyledH1>
          <StyledP>
            Kandir is the only review platform dedicated to giving more power,
            insights and sales intelligence to professional sellers. Through our
            network, we provide complete transparency into procurement teams and
            their purchase process, enabling you to improve outcomes and drive
            better results across your organization.
          </StyledP>
        </TextContainer>
        {screenSize === WindowSizeEnum.LARGE && (
          <Image
            src={require('../../assets/images/welcomePage/womanStockWelcomePage.png')}
            alt="Welcome Image"
          />
        )}
      </Panel>
      <Panel color="blue">
        <TextContainer>
          <StyledChip type="light" label="Convert Demands" />
          <StyledH1>Create and Convert Demand, Across any Channel</StyledH1>
          <StyledP>
            Every day, tens of thousands of sales professionals are forced to
            recreate the wheel and engage in a buying process with little to no
            insight into how procurement teams work, leaving the power entirely
            in the hands of the buyer.
          </StyledP>
          <StyledP>
            After decades of dealing with the same problem over and over, our
            founders realized “What if there was a way to tap into our peers to
            get first hand learnings and accounts of how a company, procurement
            team and even an individual behaves?” And from that epiphany, Kandir
            was born. We aggregate ratings, reviews and a plethora of insights
            into every portion of how businesses purchase software, helping
            sales teams navigate the “last mile” of the sales process.
          </StyledP>
          <StyledP>
            Kandir is driven by the singular vision of leveling the playing
            field and putting the power back into the hands of the sellers by
            providing them with actionable insights and behaviors that empower
            them to make smarter decisions when negotiating terms.
          </StyledP>
        </TextContainer>
        {screenSize === WindowSizeEnum.LARGE && (
          <Image
            src={require('../../assets/images/welcomePage/stockBubbleWelcomePage.png')}
            alt="Welcome Image"
          />
        )}
      </Panel>
      <Panel color="white">
        <CenteredTextContainer>
          <StyledChip type="light" label="Why Sales Teams" />
          <StyledH1>Why Sales Teams</StyledH1>
          <TextBoxContainer isWide>
            <TextBox>
              <TextBoxHeader>01</TextBoxHeader>
              <P bold>Improve win rates and shorten sales cycles</P>
              <br />
              <P>
                Know how your buyer operates before you ever connect with them
              </P>
            </TextBox>
            <TextBox>
              <TextBoxHeader>02</TextBoxHeader>
              <P bold>Preserve higher price points, avoid deeper discounts</P>
              <br />
              <P>
                By leveraging data from the community, sellers can better
                prepare for asks, and know if, when and how much of a discount
                is expected to get deals done.
              </P>
            </TextBox>
            <TextBox>
              <TextBoxHeader>03</TextBoxHeader>
              <P bold>
                Reduce risk and unnecessary acceptance of onerous terms
              </P>
              <br />
              <P>
                Understand the hot buttons before going to contracting so you
                can shorten negotiation time and collaborate with your buyer to
                ensure you are agreeing to terms that protect your company.
              </P>
            </TextBox>
          </TextBoxContainer>
        </CenteredTextContainer>
      </Panel>
      <Panel color="dark">
        {screenSize === WindowSizeEnum.LARGE && (
          <Image
            src={require('../../assets/images/welcomePage/stockBubbleDarkWelcomePage.png')}
            alt="Welcome Image"
          />
        )}
        <TextContainer color="white">
          <StyledChip type="dark" label="About Us" />
          <StyledH1 color="white">
            Understand your True B2B Target Market
          </StyledH1>
          <StyledP color="white">
            We aggregate reviews across all industries from verified
            professional sellers that share their unfiltered and unbiased
            experiences and observations when selling to organizations of all
            sizes. We then give you the ability to find key data points so you
            can get smart and build your strategy for negotiating key items,
            responding to RFPs or handling requests for discount.
          </StyledP>
        </TextContainer>
      </Panel>
      <Panel color="white">
        <CenteredTextContainer>
          <StyledChip type="light" label="Features and Benefits" />
          <StyledH1>Features and Benefits</StyledH1>
          <TextBoxContainer isWide>
            {FEATURES_AND_BENEFITS_MAPPINGS.slice(0, 4).map(
              (feature, index) => (
                <TextBox key={index}>
                  <img
                    src={feature.imageLink}
                    style={{ marginBottom: '12px' }}
                    alt={feature.alt}
                  />
                  <StyledP bold>{feature.subtitle}</StyledP>
                  <P>{feature.text}</P>
                </TextBox>
              )
            )}
          </TextBoxContainer>
          <TextBoxContainer
            isWide={screenSize === WindowSizeEnum.SMALL ? true : false}
          >
            {FEATURES_AND_BENEFITS_MAPPINGS.slice(4, 7).map(
              (feature, index) => (
                <TextBox
                  key={index}
                  isWide={screenSize === WindowSizeEnum.LARGE ? true : false}
                >
                  <img
                    src={feature.imageLink}
                    style={{ marginBottom: '12px' }}
                    alt={feature.alt}
                  />
                  <StyledP bold>{feature.subtitle}</StyledP>
                  <P>{feature.text}</P>
                </TextBox>
              )
            )}
          </TextBoxContainer>
        </CenteredTextContainer>
      </Panel>
      <Panel>
        <BottomPanel>
          <TextContainer>
            <StyledChip type="light" label="Various Companies" />
            <StyledH1>
              Actionable insights on the buying process for publicly traded SaaS
              companies
            </StyledH1>
          </TextContainer>
          <StyledFlexContainer>
            <div style={{ margin: 'auto' }}>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Adobe'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Salesforce'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'ServiceNow'}.jpeg`}
                />
              </CompanyLogoImageContainer>
            </div>
            <div>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Shopify'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Workday'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Snowflake'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'CrowdStrike'}.jpeg`}
                />
              </CompanyLogoImageContainer>
            </div>
            <div style={{ margin: 'auto' }}>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Atlassian'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Autodesk'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Acorns'}.jpeg`}
                />
              </CompanyLogoImageContainer>
            </div>

            <div>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Box'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'GitLab'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'C3'}.jpeg`}
                />
              </CompanyLogoImageContainer>
              <CompanyLogoImageContainer>
                <StyledCompanyLogoImage
                  src={`https://kandir-images.s3.eu-west-2.amazonaws.com/Company Logos/${'Braze'}.jpeg`}
                />
              </CompanyLogoImageContainer>
            </div>
          </StyledFlexContainer>
        </BottomPanel>
      </Panel>
    </>
  );
};

export default PreLogin;
