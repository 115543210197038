import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Button, SwipeableDrawer } from '@mui/material';
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import useWindowResize, {
  Dimensions,
  WindowSizeEnum,
} from '../../shared/hooks/useWindowResize';
import { Breakpoints, H2, H4 } from '../../shared/styles';
import { Company } from '../authentication/types/types.auth';
import CompanyCard from './components/CompanyCard';
import CompanySearchInput from './components/CompanySearchInput';
import { Container } from '../../shared/components/Container';
import axios from 'axios';
import { UserContext } from '../../shared/contexts/UserContext';
import LoadingProgress from '../../shared/components/LoadingProgress';
import ReviewDialog from '../review/components/ReviewDialog';
import CustomSnackbar, {
  SnackBarConfig,
} from '../../shared/components/Snackbar';
import { FilterCompanyEnum } from './types/company.types';
import Pills from '../../shared/components/Pill';
import { FetchCompaniesParams, fetchCompanies } from './api/api';
import CompanyFilterDropdown from './components/CompanyFilterDropdown';

export enum AnchorPositionEnum {
  OPEN_TOP = 'top',
  OPEN_LEFT = 'left',
  OPEN_RIGHT = 'right',
  OPEN_BOTTOM = 'bottom',
}

const NoResultWrapper = styled.div`
  text-align: center;
`;

const StyledNoResultHeader = styled(H4)`
  margin: var(--spacing-md) auto;
  align-items: center;
  text-align: center;
`;

const PageWrapper = styled.div`
  padding: var(--spacing-md);
`;

const StyledHeader = styled(H2)`
  margin: var(--spacing-md) auto;
  align-items: center;
  text-align: center;
`;

const SearchWrapper = styled.div`
  margin: auto;
  text-align: center;
  width: 65%;
`;

const FilterWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const CompaniesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
`;

const CompanySearchPage = () => {
  const [loading, setLoading] = useState(false);
  const [companies, setCompanies] = useState<Array<Company>>([]);
  const [currentCompany, setCurrentCompany] = useState<Company>();
  const [snackbarConfig, setSnackbarConfig] = useState<SnackBarConfig>();
  const [filters, setFilters] = useState([
    {
      id: 0,
      filterValue: FilterCompanyEnum.ALL,
      label: 'Alphabetical',
      isActive: true,
    },
    {
      id: 1,
      filterValue: FilterCompanyEnum.MOST_RECENT,
      label: 'Latest Reviews',
      isActive: false,
    },
    {
      id: 2,
      filterValue: FilterCompanyEnum.MOST_REVIEWED,
      label: 'Most Reviews',
      isActive: false,
    },
  ]);
  const [topFilters, setTopFilters] = useState([
    {
      id: 0,
      filterValue: FilterCompanyEnum.ALL,
      label: 'Enterprise Cloud',
      isActive: true,
    },
    {
      id: 1,
      filterValue: FilterCompanyEnum.MOST_RECENT,
      label: 'All Public Companies',
      isActive: false,
    },
  ]);
  const [openReviewDialog, setOpenReviewDialog] = useState(false);
  const [isAddCompany, setIsAddCompany] = useState(false);
  const [searchTerm, setSearchTerm] = useState('');
  const [isMobileDrawOpen, setIsMobileDrawOpen] = useState(false);
  const [screenSize, setScreenSize] = useState<WindowSizeEnum>(
    window.innerWidth > Breakpoints.md
      ? WindowSizeEnum.LARGE
      : WindowSizeEnum.SMALL
  );
  const navigate = useNavigate();

  const { user } = useContext(UserContext);

  const setSize = useCallback((dimensions: Dimensions) => {
    if (dimensions.width > Breakpoints.md) {
      setScreenSize(WindowSizeEnum.LARGE);
    } else {
      setScreenSize(WindowSizeEnum.SMALL);
    }
  }, []);

  useWindowResize(setSize);

  const toggleMobileFilters = () => {
    setIsMobileDrawOpen(!isMobileDrawOpen);
  };

  const handleSnackBarClose = () => {
    setSnackbarConfig({ ...snackbarConfig, open: false });
  };

  const cancelRating = () => {
    setSnackbarConfig({
      type: 'error',
      message: 'You must have engaged with this company to leave a review.',
      open: true,
    });
  };

  const ratingSuccess = () => {
    setSnackbarConfig({
      type: 'success',
      message:
        'Company and Review have been successfully submitted for approval.',
      open: true,
    });
  };

  const companyOptions = companies
    ?.filter((company: Company) => company.isApproved)
    .map((company) => {
      return {
        label: company.companyName,
      };
    });

  const getCompanies = async () => {
    try {
      const searchTerms = {
        searchTerm: searchTerm,
      };
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/company/search`,
        searchTerms
      );
      if (Array.isArray(res.data)) {
        setCompanies(res.data);
      }
    } catch (error) {
      setCompanies([]);
    }
  };

  const memoizedFetchCompanies = useMemo(() => {
    const fetchData = async () => {
      setLoading(true);
      try {
        const filter = filters.find((filter) => filter.isActive === true);
        const params: FetchCompaniesParams = {
          filter: filter?.filterValue || FilterCompanyEnum.ALL,
        };

        const companies = await fetchCompanies(params);

        setCompanies(companies);

        setLoading(false);
      } catch (error) {
        setSnackbarConfig({
          open: true,
          type: 'error',
          message: 'Server error, please try again later',
        });
        setLoading(false);
      }
    };

    return fetchData;
  }, [user, filters]);

  const handleFilterClicks = (id: number) => {
    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.id === id) {
          return { ...filter, isActive: true };
        } else {
          return { ...filter, isActive: false };
        }
      });
    });
  };

  const handleDropdownFilterChange = (filterValue: FilterCompanyEnum) => {
    setFilters((prevFilters) => {
      return prevFilters.map((filter) => {
        if (filter.filterValue === filterValue) {
          return { ...filter, isActive: true };
        } else {
          return { ...filter, isActive: false };
        }
      });
    });
  };

  useEffect(() => {
    getCompanies();
  }, [searchTerm, user]);

  useEffect(() => {
    memoizedFetchCompanies();
  }, [filters]);

  if (!companies || loading) {
    return <LoadingProgress />;
  }

  return (
    <PageWrapper>
      {screenSize === WindowSizeEnum.SMALL ? (
        <>
          <MenuIcon onClick={toggleMobileFilters} />
          <SwipeableDrawer
            anchor={AnchorPositionEnum.OPEN_LEFT}
            open={isMobileDrawOpen}
            onClose={toggleMobileFilters}
            onOpen={toggleMobileFilters}
          >
            <Container>
              <CompanySearchInput
                handleChange={setSearchTerm}
                options={companyOptions}
                label="Search Companies"
              />
            </Container>
          </SwipeableDrawer>
        </>
      ) : (
        <SearchWrapper>
          <StyledHeader>Search Companies</StyledHeader>
          <div style={{ display: 'flex' }}>
            <CompanySearchInput
              handleChange={setSearchTerm}
              options={companyOptions}
              label="Search Companies"
            />
            {/* <div style={{ marginTop: '14px' }}>
              <CompanyFilterDropdown
                filters={filters}
                onFilterChange={handleDropdownFilterChange}
              />
            </div> */}
          </div>
          {/* <FilterWrapper>
            <Pills pills={topFilters} handleFilterClick={handleFilterClicks} />
          </FilterWrapper> */}
        </SearchWrapper>
      )}

      {companies?.length ? (
        <CompaniesWrapper>
          {companies?.map((company: Company, i) => {
            return (
              <CompanyCard
                key={i}
                companyData={company}
                openReviewDialog={() => {
                  setCurrentCompany(company);
                  setOpenReviewDialog(true);
                }}
                navToProfile={() => {
                  navigate(`${company.companyName}`);
                }}
              />
            );
          })}
        </CompaniesWrapper>
      ) : (
        <NoResultWrapper>
          {user?.id && (
            <>
              <StyledNoResultHeader>
                Don't see the company you're looking for?
              </StyledNoResultHeader>
              <Button
                variant="contained"
                onClick={() => {
                  setIsAddCompany(true);
                  setOpenReviewDialog(true);
                }}
              >
                Add a company to our database with a review
              </Button>
            </>
          )}
        </NoResultWrapper>
      )}
      <ReviewDialog
        open={openReviewDialog}
        isAddCompany={isAddCompany}
        setOpen={setOpenReviewDialog}
        companyName={currentCompany?.companyName || ''}
        companyId={currentCompany?.id || 0}
        cancelRating={cancelRating}
        ratingSuccess={ratingSuccess}
      />
      <CustomSnackbar config={snackbarConfig} setOpen={handleSnackBarClose} />
    </PageWrapper>
  );
};

export default CompanySearchPage;
