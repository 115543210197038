import { Grid, TextField } from '@mui/material';
import styled from 'styled-components';
import Select from '../../../shared/components/Select';
import { P } from '../../../shared/styles';
import CreatableSelect from 'react-select/creatable';
import makeAnimated from 'react-select/animated';
import {
  AuthEnum,
  COMPANY_SIZE_OPTIONS,
  Company,
  FormField,
  FormFieldsValues,
  INDUSTRY_SELECT_OPTIONS,
} from '../types/types.auth';
import axios from 'axios';
import { useContext, useEffect, useState } from 'react';
import LoadingProgress from '../../../shared/components/LoadingProgress';
import { UserContext } from '../../../shared/contexts/UserContext';
import { Link } from 'react-router-dom';

type SelectOption = {
  label: string | undefined;
  value: string | undefined;
};

const animatedComponents = makeAnimated();

type AuthFormFieldsProps = {
  formFields: Array<FormField>;
  handleFormFieldChange: (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedOptions?: any
  ) => void;
  defaultValues?: FormFieldsValues;
  isEdit?: boolean;
  industryValue?: string;
  authType?: AuthEnum;
};

const StyledText = styled(P)`
  text-align: center;
`;

const StyledSelect = styled(CreatableSelect)`
  height: 65px;
  width: 100%;
`;

const AuthFormFields = (props: AuthFormFieldsProps) => {
  const {
    handleFormFieldChange,
    formFields,
    defaultValues,
    isEdit,
    industryValue,
    authType,
  } = props;
  const [companies, setCompanies] = useState<Array<SelectOption>>([]);

  const { user } = useContext(UserContext);

  const getCompanyNames = (companies: Array<Company>) => {
    return companies.map((company) => ({
      value: company.companyName,
      label: company.companyName,
    }));
  };

  const getCompanies = async () => {
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_BASE_URL}/company/search`,
        ''
      );
      if (Array.isArray(res.data)) {
        const companyNames = getCompanyNames(res.data);
        setCompanies(companyNames);
      }
    } catch (error) {
      setCompanies([]);
    }
  };

  const onChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    selectedOptions?: any
  ) => {
    handleFormFieldChange(e, selectedOptions);
  };

  useEffect(() => {
    if (companies.length) return;
    getCompanies();
  }, [user]);

  if (!companies.length) {
    return <LoadingProgress />;
  }

  return (
    <Grid container spacing={2}>
      {formFields.map((field) => {
        return (
          <Grid item xs={field.xs} sm={field.sm} key={field.name}>
            {field.name === 'industry' ? (
              <Select
                label={field.label}
                name={field.name}
                required={true}
                currentValue={industryValue}
                defaultValue={
                  defaultValues &&
                  defaultValues[field.name as keyof FormFieldsValues]
                }
                options={INDUSTRY_SELECT_OPTIONS}
                onChange={onChange}
              />
            ) : field.name === 'companySize' ? (
              <Select
                label={field.label}
                name={field.name}
                required={true}
                defaultValue={
                  defaultValues &&
                  defaultValues[field.name as keyof FormFieldsValues]
                }
                options={COMPANY_SIZE_OPTIONS}
                onChange={onChange}
              />
            ) : (
              <TextField
                name={field.name}
                fullWidth
                disabled={field.name === 'email' && isEdit}
                defaultValue={
                  defaultValues &&
                  defaultValues[field.name as keyof FormFieldsValues]
                }
                type={field.type}
                label={field.label}
                onChange={onChange}
                required
              />
            )}
          </Grid>
        );
      })}
      {authType && authType === AuthEnum.SIGN_UP && (
        <Grid item>
          <StyledText>
            Password must be longer than 8 characters, and include both an
            uppercase, lowercase letter and a number.
          </StyledText>
        </Grid>
      )}
      {authType && authType === AuthEnum.WAITLIST && (
        <Grid item>
          <StyledText style={{ textAlign: 'left' }}>
            Kandir is a professional network community of tech professionals.
            Access to the community will be priotized by the level of experience
            our users have selling into our targeted database of publicly traded
            SaaS technology companies.
          </StyledText>
          &nbsp;
          <StyledText style={{ textAlign: 'left' }}>
            View our <Link to="/company-search">Company Search here</Link>
          </StyledText>
          &nbsp;
          <StyledText style={{ textAlign: 'left' }}>
            Please indicate which of the companies in our database you have sold
            into or renewed:
          </StyledText>
          &nbsp;
        </Grid>
      )}

      {authType && authType === AuthEnum.WAITLIST && (
        <div style={{ width: '100%', marginLeft: '20px' }}>
          <StyledSelect
            closeMenuOnSelect={false}
            components={animatedComponents}
            isMulti
            name="companiesWorkedWith"
            options={companies}
            onChange={(selectedOptions, { name }) =>
              handleFormFieldChange(
                { target: { name } } as React.ChangeEvent<
                  HTMLTextAreaElement | HTMLInputElement
                >,
                selectedOptions
              )
            }
          />
        </div>
      )}
    </Grid>
  );
};
export default AuthFormFields;
