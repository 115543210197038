export enum ReviewDepth {
  BASIC = 'BASIC',
  DEEP_DIVE = 'DEEP_DIVE',
}

export enum RatingAspect {
  CLARITY = 'clarity',
  RESPONSIVENESS = 'responsiveness',
  TRANSPARENCY = 'transparency',
  UNDERSTANDING = 'understanding',
}

export enum ReviewStep {
  BASIC_REVIEW,
  PURCHASE_DETAILS,
  EVALUATION,
  LEGAL,
  NEGOTIATION,
  RFP,
}

// These are the summaries returned from the user and company endpoints
export type RatingSummary = {
  name: string;
  rating: string;
  user: string;
  type: string;
};

export type BasicReview = {
  dealSize?: string;
  dealClose?: string;
  dealLength?: string;
  dealType?: string;
  dealArea?: string;
  dealRfp?: string;
  commentBuyerWell?: string;
  commentBuyerBetter?: string;
  commentWinLose?: string;
  commentAdvice?: string;
  id?: number;
  Ratings: Array<RatingSummary>;
  CompanyId?: number;
  UserId?: number;
  companyName?: string;
  createdAt?: Date;
  reviewDepth?: ReviewDepth;
  reviewType?: string;
};

export type PurchaseDetailsReview = {
  championEffectiveness?: string;
  definedBudget?: string;
  salesCycleLength?: string;
  buyerFiscalYearEnd?: string;
  paymentCycle?: string;
  economicBuyerName?: string;
  championName?: string;
};

export type EvaluationReview = {
  buyerReplacingVendor?: string;
  noDecisionMakers?: string;
  criteriaArticulatedByBuyer?: string;
  criteriaChangeComment?: string;
  securityRequirementsComment?: string;
  criteriaArticulatedByBuyerComment?: string;
  requireTrial?: string;
  trialAccommodated?: string;
  platFormList?: string[];
  durationSecurityReview?: string;
  securityDifficulty?: string;
  submitRoi?: string;
  approversList?: string[];
};

export type LegalReview = {
  legalReviewLength?: string;
  legalReviewDifficulty?: string;
  marketingPermission?: string;
  autoRenewalClause?: string;
  paperworkOption?: string;
  customerAskLiability?: string;
  endAskLiability?: string;
  liabilityComment?: string;
  notableGenComment?: string;
};

export type NegotiationReview = {
  terminationConvenience?: string;
  forcedTerminationAcceptance?: string;
  forcedTerminationAcceptanceComment?: string;
  noticeHonored?: string;
  noticeSelection?: string;
  procurementThreshold?: string;
  procurementFairness?: string;
  procurementTactics?: string;
  competitiveBids?: string;
  discountGiven?: string;
  fundsReleased?: string;
  negotiationTipsComment?: string;
  fairnessComment?: string;
};

export type RfpReview = {
  rfpWin?: string;
  rfpInvolvement?: string;
  rfpInfluence?: string;
  rfpLength?: string;
  accpetanceFormatComment?: string;
  tipsComment?: string;
};
